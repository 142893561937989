import React from "react"
import "../styles/styles.scss"
import LsPageComponent from "../components/lsPageComponent"
import { Helmet } from "react-helmet"
const LSPage = () => (
    <div>
        <Helmet title="Security-Alarm-KMU" defer={false}>
        <html lang="de" />
        <meta name="description" content="ALARM-Projekt -- Security Game 07 -- " />
        <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU, Informationsklassen" />
        </Helmet>
    <LsPageComponent 
        ls="7" 
        game="ls7"
        test="true"
        questionairs="false"
        motto="Der Ransomware-Angriff"
        chapterUrl="https://client.gamebook.io/alarm-stories/live/index.html?episode=https://exg-gb-beta.s3.eu-west-1.amazonaws.com/file-uploads/exports/621de52e76dc100013b82882/6231c067d4e9a90016f2cdbf/Chapter_1_24.zip"/>
    </div>
)

export default LSPage
